<!--
 * @Author: your name
 * @Date: 2021-11-25 13:37:54
 * @LastEditTime: 2021-12-23 16:45:35
 * @LastEditors: Please set LastEditors
 * @Description: 解决方案 图标加描述
 * @FilePath: \new-website-1123\src\views\programme\components\iconMsg.vue
-->
<template>
  <div class="programme-icons">
    <span class="iconfont" :class="icon"></span>
    <span class="text">
      <slot name="text1"></slot>
    </span>
    <span class="text text2">
      <slot name="text2"></slot>
    </span>
    <span class="line-span"></span>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.programme-icons {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid transparent;
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 220px;
  // height: 300px;
  height: 32vh;
  max-height: 300px;
  margin-right: 106px;
  transition: all 0.3s;
  padding: 20px;
  margin-bottom: 30px;
  cursor: default;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .text {
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
    &.text2 {
      height: 38px;
    }
  }
  .iconfont {
    transition: all 0.3s;
    width: 56px;
    height: 56px;
    // margin-top: 40px;
    margin-bottom: 5vh;
    font-size: 56px;
  }
  .line-span {
    width: 40px;
    height: 3px;
    background-color: rgba($color: #fff, $alpha: 0.3);
    transition: all 0.3s;
    margin-top: 16px;
  }
  &:hover {
    border-color: $color-active;
    .iconfont {
      color: $color-active;
    }
    .line-span {
      width: 80px;
      background-color: $color-active;
    }
  }
}

@media screen and (max-height: 800px) {
  .programme-icons {
    height: 30vh;
    .iconfont {
      width: 42px;
      height: 42px;
      font-size: 42px;
      margin-bottom: 4vh;
    }
    .text {
      font-size: 16px;
      line-height: 26px;
      &.text2 {
        height: 28px;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .programme-icons {
    height: 2.29rem;

    margin-right: 0;
    width: 48%;
    &:nth-child(2n) {
      margin-left: 2%;
    }

    .iconfont {
      width: 0.38rem;
      height: 0.38rem;
      font-size: 0.38rem;
      margin-bottom: 0.18rem;
    }
    .text {
      font-size: 0.15rem;
      line-height: 0.24rem;
      &.text2 {
        height: 0.25rem;
      }
    }
  }
}
</style>

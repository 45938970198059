<!--
 * @Author: your name
 * @Date: 2021-11-25 11:27:50
 * @LastEditTime: 2023-02-10 15:50:20
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 解决方案 水肥一体
 * @FilePath: \new-website-1123\src\views\programme\water\index.vue
-->
<template>
  <div class="programme-water">
    <div class="banner-box">
      <w-banner :url="banner">
        <div class="banner-content-div">
          <span class="title-span">水肥一体化解决方案</span>
          <span class="desc-span"
            >上太水肥一体化系统根据作物种类及其需水需肥规律，设置周期性水肥灌溉计划；根据所测得土壤水分和气象信息，</span
          >
          <span class="desc-span"
            >智能修正灌溉计划，实现自主高效的水肥一体化管理。施肥机可按照用户设定的配方，自动控制灌溉量、吸肥量、肥液浓度、</span
          >
          <span class="desc-span"
            >酸碱度等水肥过程，以此充分提高水肥利用率，实现节水、节肥，改善土壤环境，提高作物品质。</span
          >
        </div>
      </w-banner>
    </div>
    <!-- 水肥一体化系统组成 -->
    <div></div>
    <w-form class="opacity-page scale-page" :class="clsCurrent"></w-form>
    <!-- 轮播图 -->
    <w-swiper
      class="opacity-page scale-page"
      :title="{
        text: '应用',
        color: '场景',
      }"
      :dataList="sceneList"
      v-if="sceneList.length>0"
    >
      <template v-slot:desc>
        本系统广泛应用于大田、温室、果园等种植灌溉作业场景
      </template>
    </w-swiper>
    <!-- 功能介绍 -->
    <w-function class="opacity-page scale-page"></w-function>
    <!-- 设备 -->
    <w-device
      class="opacity-page w-device"
      :title="{
        text: '关联硬件',
        color: '设备',
      }"
      :device-list="deviceList"
      :active="deviceActive"
      @change="deviceActive = $event"
    >
      <img :src="currentDevice.cover" alt="" />
    </w-device>

    <m-device class="m-device" :list="deviceList"></m-device>
  </div>
</template>

<script>
import wBanner from "../components/banner.vue";
import wForm from "./form.vue";
import wSwiper from "../components/swiper.vue";
import wFunction from "./function.vue";
import wDevice from "../components/device.vue";
import mDevice from "../components/deviceMobile.vue";
import {getNesViewApi} from '@/api/index.js'
import {getBannerList}from '@/utils/getBanner.js'
export default {
  name: "programme-water",
  components: {
    wBanner,
    wForm,
    wSwiper,
    wFunction,
    wDevice,
    mDevice,
  },
  data() {
    return {
      banner:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/water/banner.jpg",
      sceneList: [],
      deviceList: [
        {
          title: "智能水肥机",
          desc: "通过云平台实时远程监控系统状态、远程操作系统、远程调整灌溉计划与配置",
          key: 1,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg8.png",
          path: "/special/SF101",
        },
        {
          title: "光伏自动气象测报站ST-X101",
          desc: "光伏自动气象测报站可监测空气温湿度、风速、风向、气压、雨量、光照强度、总辐射量等常规气象要素，可在无人值守的恶劣环境下全天候全自动运行，将所测数据通过4G/5G网络传输到物联网平台存储和分析。系统利用太阳能供电，无需另外接电。",
          key: 2,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg6.png",
          path: "/special/x101",
        },
        {
          title: "太阳能墒情仪ST-SQ101",
          desc: "采用介电常数原理监测土壤水分含量以及温度状态进行动态观测，并可利用4G/5G技术，将所测数据上报到远程联物网平台。整体可以检测3层~5层或定制层数土壤温湿度状态；可以快速、全面的了解土壤墒情信息，科学地制定灌溉计划与抗旱救灾方案。",
          key: 3,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg7.png",
          path: "/special/SQ101",
        },
      ],
      deviceActive: 1,
    };
  },
  created() {this.getBanner()},
  mounted() {
    this.addView()

  },
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
    clsCurrent() {
      // if (window.innerWidth > 800) {
      //   return "scale-page sticky-page";
      // }
      return "";
    },
  },
  methods: {
    getBanner(){
      getBannerList('SFYTJF001Ban003').then(res=>{
        this.sceneList = res
      })
    },
    addView(){
      getNesViewApi({type:2,pid:"6233d80dad4b4de1a53506d37aa67d3e"})
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  overflow: hidden;
  // height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  .banner-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding-bottom: 190px;
    .title-span {
      font-size: 54px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .desc-span {
      font-size: 22px;
      font-weight: 400;
      line-height: 40px;
    }

    @media screen and (max-width: 1400px) {
      .title-span {
        font-size: 44px;
      }
      .desc-span {
        font-size: 18px;
      }
    }
  }
}

.m-device {
  display: none;
}

@media screen and (max-width: $mobile-width) {
  .banner-box {
    overflow: hidden;
    width: 100%;
    ::v-deep {
      .programme-banner img {
        width: 150%;
      }
    }
    .banner-content-div {
      text-align: center;
      padding-bottom: 5%;
      width: 86%;
      .title-span {
        font-size: 0.49rem;
        font-weight: bold;
        margin-bottom: 0.27rem;
      }
      .desc-span {
        font-size: 0.2rem;
        font-weight: 400;
        line-height: 0.36rem;
      }
      .banner-ul {
        margin-top: 10%;
        .banner-li {
          .icon-span {
            width: 0.36rem;
            height: 0.36rem;
            font-size: 0.36rem;
          }
          .label-span {
            margin-top: 0.18rem;
            font-size: 0.15rem;
          }
        }
      }
    }
  }

  .w-device {
    display: none;
  }
  .m-device {
    display: block;
  }
}
</style>

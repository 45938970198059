<!--
 * @Author: your name
 * @Date: 2021-11-25 16:11:22
 * @LastEditTime: 2021-12-24 09:17:28
 * @LastEditors: Please set LastEditors
 * @Description: 水肥一体化系统组成
 * @FilePath: \new-website-1123\src\views\programme\water\form.vue
-->

<template>
  <div class="water-form">
    <p-title>
      水肥一体化系统
      <template v-slot:color>组成</template>
    </p-title>
    <div class="content-div">
      <p-icon v-for="item in dataList" :key="item.key" :icon="item.icon">
        <template v-slot:text1>{{ item.text1 }}</template>
        <template v-slot:text2
          ><span style="display: block; height: 20px"></span
        ></template>
      </p-icon>
    </div>
  </div>
</template>

<script>
import pTitle from "../components/title.vue";
import pIcon from "../components/iconMsg.vue";
export default {
  name: "water-form",
  components: {
    pTitle,
    pIcon,
  },
  data() {
    return {
      dataList: [
        {
          key: 1,
          icon: "iconwulianwangpingtai",
          text1: "物联网平台",
          text2: "",
        },
        {
          key: 2,
          icon: "iconshangqingcaijizhan",
          text1: "墒情采集站",
          text2: "",
        },
        {
          key: 3,
          icon: "iconshifeiji",
          text1: "施肥机",
          text2: "",
        },
        {
          key: 4,
          icon: "iconguolvxitong",
          text1: "过滤系统",
          text2: "",
        },
        {
          key: 5,
          icon: "iconfamenkongzhiqi",
          text1: "阀门控制器",
          text2: "",
        },
        {
          key: 6,
          icon: "icondiancifa",
          text1: "电磁阀",
          text2: "",
        },
        {
          key: 7,
          icon: "icontianjianguanlu",
          text1: "田间管路",
          text2: "",
        },
        {
          key: 8,
          icon: "iconshipinjiankong",
          text1: "视频监控",
          text2: "",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.water-form {
  padding-top: 100px;
  width: 1200px;
  margin: auto;
  // height: 100vh;
  padding-bottom: 50px;
  .content-div {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 60px;
  }
}
@media screen and (max-width: $mobile-width) {
  .water-form {
    padding-top: $mobile-header-height;
    width: 100%;
    padding-bottom: 20px;
    .content-div {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      margin-top: 0.36rem;
    }
  }
}
</style>

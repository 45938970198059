<!--
 * @Author: your name
 * @Date: 2021-11-25 16:22:34
 * @LastEditTime: 2023-02-10 15:49:15
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 功能介绍
 * @FilePath: \new-website-1123\src\views\programme\water\function.vue
-->
<template>
  <div class="water-function">
    <f-title>
      功能
      <template v-slot:color>介绍</template>
    </f-title>
    <div class="content-div">
      <div class="img-div">
        <img
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/water/function.png"
          alt="上太科技"
          ref="img"
          @load="imgLoad"
        />
      </div>
      <ul class="right-ul" ref="right">
        <li v-for="item in dataList" :key="item.id">
          <span class="label-span">{{ item.label }}</span>
          <span class="desc-span">{{ item.desc }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import fTitle from "../components/title.vue";
export default {
  name: "water-function",
  components: {
    fTitle,
  },
  data() {
    return {
      dataList: [
        {
          id: 1,
          label: "肥料混合",
          desc: "将各液肥桶内的肥料按比列进行混合",
        },
        {
          id: 2,
          label: "实时监测",
          desc: "实时监测水源水位、流量、分析故障",
        },
        {
          id: 3,
          label: "远程操作",
          desc: "远程调整灌溉计划与配置",
        },
        {
          id: 4,
          label: "自动运行记录",
          desc: "总用水量、分灌区用水量统计、用肥量统计",
        },
      ],
    };
  },
  created() {},
  mounted() {
    window.addEventListener("resize", this.imgLoad, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.imgLoad, false);
  },
  methods: {
    imgLoad() {
      this.$refs.right.style.height = this.$refs.img.height + "px";
    },
  },
};
</script>

<style lang='scss' scoped>
.water-function {
  padding-top: 80px;
  padding-bottom: 50px;
  height: 100%;

  .content-div {
    display: flex;
    justify-content: space-between;
    background-color: rgba($color: #fff, $alpha: 0.05);
    width: 78%;
    margin: 60px auto 0;
    .img-div {
      width: 70%;
      img {
        width: 100%;
      }
    }
    .right-ul {
      width: 30%;
      min-width: 480px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 60px 80px;
      li {
        // flex: 1;
        display: flex;
        flex-direction: column;
        color: #fff;
        padding-top: 18px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 56px;
          height: 3px;
          background-color: $color-active;
        }
        .label-span {
          font-size: 32px;
          font-weight: 400;
        }
        .desc-span {
          font-size: 16px;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.5);
          margin-top: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .content-div {
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .water-function {
    padding-top: $mobile-header-height;
    padding-bottom: 0.36rem;
    height: auto;

    .content-div {
      flex-direction: column;
      background-color: transparent;

      .img-div {
        width: 100%;
      }

      .right-ul {
        width: 100%;
        padding: 0 6vw;
        flex-wrap: wrap;
        // justify-content: space-between;
        li {
          flex: unset;
          width: 48%;
          padding-top: 10px;
          margin-top: 20px;
          &::before {
            content: "";
            position: absolute;
            width: 50px;
            height: 3px;
            top: 0;
            left: 0;
            background-color: $color-active;
          }
          .label-span {
            font-size: 0.29rem;
            font-weight: 400;
            color: #ffffff;
            margin-top: 5px;
          }
          .desc-span {
            font-size: 0.15rem;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
</style>

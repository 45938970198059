<!--
 * @Author: your name
 * @Date: 2021-12-23 17:40:28
 * @LastEditTime: 2021-12-24 10:33:20
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\programme\components\deviceMobile.vue
-->
<template>
  <div class="mobile-device">
    <s-swiper
      :title="{
        text: '关联硬件',
        color: '设备',
      }"
      :dataList="list"
      fit="contain"
      @change="swiperChange"
    >
      <template v-slot:desc>
        {{ desc }}
      </template>
    </s-swiper>
  </div>
</template>

<script>
import sSwiper from "./swiper.vue";
export default {
  name: "",
  components: {
    sSwiper,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      desc: "",
    };
  },
  created() {
    this.swiperChange(0);
  },
  methods: {
    swiperChange(index) {
      let str =
        "诱集迁飞性害虫、照射高度500-2000m、虫水分离，智能型红外烘干APP、小程序、WEB三端查看";
      if (
        this.list[index] &&
        (this.list[index].desc || this.list[index].desc1)
      ) {
        str = this.list[index].desc || this.list[index].desc1;
      }
      this.desc = str;
    },
  },
};
</script>

<style lang='scss' scoped>
.mobile-device {
  padding-bottom: 60px;
  width: 100%;
  overflow: hidden;
}
</style>

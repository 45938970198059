<!--
 * @Author: your name
 * @Date: 2021-11-25 15:24:54
 * @LastEditTime: 2023-02-10 15:38:58
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 解决方案 管来奶设备
 * @FilePath: \new-website-1123\src\views\programme\components\device.vue
-->
<template>
  <div class="programme-device">
    <p-title>
      {{ title.text }}
      <template v-slot:color>{{ title.color }}</template>
    </p-title>
    <div class="content-div">
      <ul class="nav-ul">
        <li
          v-for="item in deviceList"
          :key="item.key"
          class="shou"
          :class="{
            'nav-active': item.key == active,
            'no-desc': !item.desc,
          }"
          @click="$emit('change', item.key)"
        >
          <span class="title-span">{{ item.title }}</span>
          <span class="desc-span" v-if="item.desc">{{ item.desc }}</span>
          <span class="col-g" @click="goMore(item)">
            查看详情
            <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png" alt="" />
          </span>
        </li>
      </ul>
      <div class="img-div">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import pTitle from "./title.vue";
export default {
  name: "programme-device",
  components: {
    pTitle,
  },
  props: {
    title: {
      type: Object,
      required: true,
    },
    deviceList: {
      type: Array,
      required: true,
    },
    active: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    goMore(item){
      this.$router.push(item.path)
    }
  },
};
</script>

<style lang="scss" scoped>
.programme-device {
  padding-top: 60px;
  padding-bottom: 50px;
  // height: 100vh;
  position: relative;
  .content-div {
    flex: 1;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 140px);
    width: 78%;
    margin: auto;
    padding-top: 1px;
    align-items: center;
    .nav-ul {
      border-left: 1px solid rgba($color: #fff, $alpha: 0.1);
      // height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 70px;
      flex: 1;
      padding: 0 50px;
      max-width: 590px;
      li {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 10px;
        &.no-desc {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 0;
          background-color: $color-active;
          top: 0;
          left: -52px;
          transition: all 0.3s;
        }
        .title-span {
          font-size: 32px;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.5);
          transition: all 0.3s;
          white-space: nowrap;
        }
        .col-g {
          font-size: 18px;
          margin-top: 20px;
          display: none;
        }
        .desc-span {
          font-size: 16px;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.5);
          line-height: 28px;
          opacity: 0;
          transition: all 0.3s;
          margin-top: 20px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: normal;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &.nav-active {
          .title-span {
            color: #fff;
          }
          .col-g {
            display: block;
          }
          .desc-span {
            opacity: 1;
          }
          &::before {
            height: 100%;
          }
        }
      }
    }

    @media screen and (max-height: 800px) {
      .nav-ul {
        li {
          .title-span {
            font-size: 28px;
          }
          .desc-span {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .img-div {
    // padding-right: 50px;
    width: 56%;
    height: 700px;
    img {
      // width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media screen and (max-height: 800px) {
      ::v-deep {
        img {
          height: 90vh;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
}
</style>
